<template>
  <div style="padding: 10vw">
    <div class="d-flex align-center" >
    <span>原图：</span>
    <img  src="https://img1.baidu.com/it/u=722430420,1974228945&fm=253&fmt=auto&app=138&f=JPEG?w=300&h=200" />
  </div>
  <div class="d-flex" style="margin-top: 3vw">
    <div class="text-center item">
      <div>contain</div>
      <img class="box_border" style="object-fit: contain" src="https://img1.baidu.com/it/u=722430420,1974228945&fm=253&fmt=auto&app=138&f=JPEG?w=300&h=200" />
    </div>
    <div class="text-center item">
      <div>cover</div>
      <img class="box_border" style="object-fit: cover" src="https://img1.baidu.com/it/u=722430420,1974228945&fm=253&fmt=auto&app=138&f=JPEG?w=300&h=200" />
    </div>
    <div class="text-center item">
      <div>fill</div>
      <img class="box_border" style="object-fit: fill" src="https://img1.baidu.com/it/u=722430420,1974228945&fm=253&fmt=auto&app=138&f=JPEG?w=300&h=200" />
    </div>
  </div>
  </div>
</template>

<script setup>
import { post, get } from "@/utils/common";
import { reactive, toRefs, onMounted, ref, onUnmounted } from "vue";
import { useRouter } from "vue-router";

let obj = reactive({
  name: "理想"
})

onMounted(() => {
  console.log("onMounted", "" ?? "111", obj.object?.name);
  toGet();
})

const toGet = async() => {
  const _get = await get("knowledgeBase/queryHotSpot").then(r => r.hotSpot || []);
  console.log("get ==", _get);
}

onUnmounted(() => {
  console.log("onUnmounted")
})

const change = () => {
    console.log("change ===");
}

</script>

<style lang="less" scoped>
.box_border {
  width: 200px;
  height: 200px;
  border: 1px solid red;
  
}

.item {
  margin-right: 3vw;
}
</style>
